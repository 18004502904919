import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import axios from 'axios';

const TabUpload = ({ setData }) => {
  const csvFiles = [
    'bobpublic88.gmail.com.csv',
    'dns.cloudflare.com.csv',
    'mayamai1241.gmail.com.csv',
    'tbpublic88.gmail.com.csv',
    'zhanghang8819.gmail.com.csv',
  ];

  const [dropdownValue, setDropdownValue] = useState(() => {
    const savedValue = localStorage.getItem('selectedCSV');
    return savedValue || csvFiles[0];
  });
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [selectedCSV, setSelectedCSV] = useState('');
  const [file, setFile] = useState(null);
  const [saveConfirmation, setSaveConfirmation] = useState('');

  const fileOptions = csvFiles.map((file) => ({
    key: file,
    text: file,
    value: file,
  }));

  useEffect(() => {
    const savedDomains = localStorage.getItem('domains');
    if (savedDomains) {
      setTextAreaValue(JSON.parse(savedDomains).join('\n'));
    }

    // Load the default CSV file on initial render
    fetch(`${process.env.PUBLIC_URL}/${dropdownValue}`)
      .then((response) => response.text())
      .then((data) => {
        setTextAreaValue(data);
        const domains = data.split('\n').filter((line) => line.trim() !== '');
        setSelectedCSV(domains.join('\n'));
      })
      .catch((err) => console.error('Error loading file:', err));
  }, [dropdownValue]);

  const apiBaseUrl =
    window.location.hostname === 'localhost'
      ? 'http://localhost:5000'
      : 'https://nakfhtdj69.execute-api.us-east-2.amazonaws.com/production';

  const clearSaveConfirmation = () => setSaveConfirmation('');

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
    setIsUnsaved(true);
    clearSaveConfirmation();
  };

  const handleDropdownChange = (e, { value }) => {
    setDropdownValue(value);
    localStorage.setItem('selectedCSV', value);
    clearSaveConfirmation();

    fetch(`${process.env.PUBLIC_URL}/${value}`)
      .then((response) => response.text())
      .then((data) => {
        setTextAreaValue(data);
        const domains = data.split('\n').filter((line) => line.trim() !== '');
        setSelectedCSV(domains.join('\n'));
      })
      .catch((err) => console.error('Error loading file:', err));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    clearSaveConfirmation();
  };

  const analyzeData = (data) => {
    const domains = data.split('\n').filter((line) => line.trim() !== '');
    if (domains.length === 0) {
      setSaveConfirmation('No valid domains to analyze.');
      return;
    }

    localStorage.setItem('domains', JSON.stringify(domains));
    setData(domains);
    analyzeDomains(domains);
  };

  const analyzeDomains = (domains) => {
    axios
      .post(`${apiBaseUrl}/analyze`, { domainList: domains })
      .then((response) => {
        localStorage.setItem('analysis_results', JSON.stringify(response.data));

        // Reset chart URLs for all tabs
        const chartTabs = [
          'numFreqChartUrl',
          'lenFreqChartUrl',
          'charFreqChartUrl',
          'tldFreqChartUrl',
        ];
        chartTabs.forEach((tab) => localStorage.setItem(tab, 'redraw.png'));

        setIsUnsaved(false);
        setSaveConfirmation('Domains analyzed and saved successfully!');
      })
      .catch((error) => {
        console.error('Error during domain analysis:', error);
        setSaveConfirmation('Error analyzing domains. Please try again.');
      });
  };

  return (
    <div className="tab-upload-container">
      <div className="input-column">
        <p>Paste in a List</p>
        <textarea
          value={textAreaValue}
          onChange={handleTextAreaChange}
          placeholder="Paste domain names here, one per line"
          rows="10"
        />
        <Button onClick={() => analyzeData(textAreaValue)} disabled={!isUnsaved}>
          Analyze and Save
        </Button>
      </div>

      <div className="input-column">
        <p>Try Sample Data</p>
        <Dropdown
          placeholder="Select CSV File"
          fluid
          selection
          value={dropdownValue}
          options={fileOptions}
          onChange={handleDropdownChange}
        />
        <Button onClick={() => analyzeData(selectedCSV)}>Analyze Sample Data</Button>
      </div>

      <div className="input-column file-upload-column">
        <p>Upload a File</p>
        <div className="file-upload-container">
          <input type="file" onChange={handleFileChange} />
          <Button
            onClick={() => {
              if (!file) {
                setSaveConfirmation('No file selected.');
                return;
              }

              const reader = new FileReader();
              reader.onload = (e) => analyzeData(e.target.result);
              reader.readAsText(file);
            }}
          >
            Analyze File
          </Button>
        </div>
      </div>

      {saveConfirmation && (
        <div style={{ marginTop: '10px', color: saveConfirmation.includes('Error') ? 'red' : 'green' }}>
          {saveConfirmation}
        </div>
      )}

      <style jsx>{`
        .file-upload-column {
          margin-left: 40px; /* Adds horizontal spacing */
        }

        .file-upload-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 100px; /* Moves buttons to the bottom of the column */
        }
      `}</style>
    </div>
  );
};

export default TabUpload;

